import React from 'react';
import { string } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import { ALL_CARDS_ROUTE, CREATE_ROUTE, SUPPORT_PROBLEM } from 'constants/navigation';
import { LOYALTY_CARD_NAV, ADD_NAV, BUG } from 'constants/font-awesome';

import SkeletonView from 'views/loyalty-card/create/skeleton';
import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/three'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const CreateView = loadable(() => import('views/loyalty-card/create'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedCreateLoyaltyCardPage = ({ passId = '' }) => {
  const {
    cmsJson: {
      pages: {
        create,
        update,
      },
    },
  } = useStaticQuery(
    graphql`
      query  {
      cmsJson {
        pages {
          create {
            title
            message
          }
          update {
            title
            message
          }
        }
      }
    }`,
  );

  let { title, message } = create;

  if (passId) {
    title = update.title;
    message = update.message;
  }

  const styles = useStyles();

  return (
   <Page
    title="Create card"
    description={message}>
     <LazyBanner/>
     <Typography variant="h2" component="h1" className={styles.heading} align="center">
     {title}
     </Typography>
     <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
      {message}
     </Typography>
     <CreateView passId={passId} fallback={<SkeletonView />}/>
     <ApplicationFooter
       navItems={[
         { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
         { route: CREATE_ROUTE, icon: ADD_NAV, message: 'Add' },
         { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
       ]}
       currentItem="Add"
       />
   </Page>
  );
};

SuspendedCreateLoyaltyCardPage.propTypes = { passId: string };

export default SuspendedCreateLoyaltyCardPage;
